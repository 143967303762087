<template>
  <div v-if="isshowgrid  && (packageList.length > 0)">
    <va-card title="Package List">
      <template slot="actions">
        <va-button color="success" v-if="(getRole() == 'ADMIN')" icon="fa fa-plus" @click="add()">Add</va-button>
      </template>
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
      </div>
      <!-- <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)">
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" v-if="(getRole() == 'ADMIN')" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table> -->
      <div class="flex md12 xs12">
        <div class="row">
          <div class="flex xs6 lg3" v-for="(value, key) in items" :key="key">
            <va-card class="mb-4 packcard" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
              <div class="row">
                <h5 class="flex md8">{{ value.package_name }}</h5>
                <va-button  flat small color="black" icon="fa fa-tv" v-if="value.packchannelflag" @click="showchannellist(value)" class="cursor_tv"></va-button>
                <va-button flat small color="black" icon="fa fa-pencil" v-if="(getRole() == 'ADMIN')" @click="edit(value)"  class="cursor"></va-button>
              </div>
              <va-separator class="separator"/>
              <p>Provider - {{value.provider_name}}</p>
              <div  v-for="(val, k) in value.provider_category" :key="k">
                <p style="font-size: 12px;">{{val.id}}</p>
              </div>
            </va-card>
          </div>
        </div>
            <va-modal
              v-model="showRemoveModal"
              size='small'
              class="flex md12"
              cancelText=""
              @ok.prevent="list()"
            >
              <template #header><h2>Package Channels</h2></template>
              <va-button flat small color="danger" icon="entypo entypo-cancel" class="model_cancel" @click="list()"></va-button><br>
              <div class="ml-4">
                <span class="pre-formatted">{{message}}</span> 
              </div>
            </va-modal>
      </div>
    </va-card>
  </div>

  <div v-else-if='isshowForm'>
    <div>
      <va-card v-if="isshowForm" :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Package Name</span>
            <va-input
              v-model.trim="form_data.package_name"
              placeholder="Enter Package Name"
              :error="!!error_msg.packageNameErrors.length"
              :error-messages="error_msg.packageNameErrors"
            />
            <span class="va-form-label va-form-required-label">Package Amount</span>
            <va-input
              type = number
              min = 0
              v-model.trim="form_data.amount"
              placeholder="Enter Package Amount"
              :error="!!error_msg.packageAmountErrors.length"
              :error-messages="error_msg.packageAmountErrors"
            />
            <span class="va-form-label va-form-required-label">Provider</span>
            <va-select
              v-model="form_data.provider_name"
              textBy="id"
              searchable
              @input="dataUpdate(form_data.provider_name)"
              :options="name_data"
              :error="!!error_msg.providerNameErrors.length"
              :error-messages="error_msg.providerNameErrors"
            />
            <span class="va-form-label va-form-required-label">Service Category</span>
            <va-select
              v-model="form_data.provider_category"
              textBy="id"
              multiple
              searchable
              :options="provider_data"
              :error="!!error_msg.providerCategoryErrors.length"
              :error-messages="error_msg.providerCategoryErrors"
            />
            <span class="va-form-label va-form-required-label">Service Type</span>
            <va-select
              v-model="form_data.provider_type"
              textBy="id"
              searchable
              :options="type_data"
              :error="!!error_msg.providerTypeErrors.length"
              :error-messages="error_msg.providerTypeErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createConfig()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateConfig()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
  <div v-else-if="showMsg">
    <va-card><center><h1>{{msg}}</h1></center></va-card>
  </div>
  <div v-else-if="packageList.length == 0 && loadFlag">
    <va-card>
      <template slot="actions" v-if="(getRole() == 'ADMIN')">
        <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
      </template>
      <center><h1>Your Package is empty. Please add package.</h1></center>
    </va-card>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'package',
  data () {
    return {
      term: null,
      perPage: 10,
      totalPages: 0,
      items: [],
      loading: false,
      showRemoveModal: false,
      message: '',
      provider_data: [],
      provider_info: [],
      name_data: [],
      type_data: [],
      packageList: [],
      package_id: '',
      form_data: {
        package_name: '',
        amount: 0,
        provider_name: [],
        provider_category: [],
        provider_type: [],
      },
      error_msg: {
        option: true,
        packageNameErrors: [],
        providerNameErrors: [],
        providerCategoryErrors: [],
        providerTypeErrors: [],
        packageAmountErrors: [],
      },
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showMsg: false,
      title: '',
      msg: '',
      loadFlag: false
    }
  },
  computed: {
    formReady () {
      return !this.error_msg.packageNameErrors.length && !this.error_msg.providerNameErrors.length && !this.error_msg.providerCategoryErrors.length && !this.error_msg.providerTypeErrors.length && !this.error_msg.packageAmountErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'package_name',
        title: 'Package Name',
      },
      {
        name: 'provider_name',
        title: 'Provider',
      },
      {
        name: 'provider_category',
        title: 'Service category',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      var getData = search(this.term, this.items)
      if (getData.length > 0) {
        getData.map(function (arg) {
          var category = ''
          arg.provider_category.map(function (thing) {
            category = (category !== '') ? category + ' ,' + thing.id : thing.id
          })
          arg.provider_category = category
        })
      }
      return getData
    },
  },
  created () {
    this.readItems()
  },
  methods: {
    readItems (page = 0) {
      this.loading = true
      this.isshowForm = false
      this.isshowgrid = true
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/package').then(response => {
        loader.hide()
        this.loadFlag = (response.body.package.length > 0) ? true : false;
        if (response.body.msg) {
          this.showMsg = true
          this.isshowgrid = false
          this.isshowForm = false
          this.msg = response.body.msg
        } else {
          let index = 0
          this.items = response.body.package.map(function (item) {
            var packchannel = response.body.channel.filter(function (packs) {
              return item.package_id == packs.package_id
            })
            item.packchannelflag = false
            var package_channel = []
            if (packchannel.length > 0) {
              item.packchannelflag = true
              packchannel.map(function (pack_name) {
		            if(package_channel.indexOf(pack_name.name) == -1){
                  package_channel.push(pack_name.name)
		            }
              })
            }
            item.packchannel = package_channel
            item.id = index++
            item.sno = index
            item.is_existing = true
            item.checkbox_value = false
            return item
          })
          this.packageList = (response.body.package.length > 0) ? response.body.package : []
          this.loading = false
          this.isshowgrid = true
          this.showMsg = false
          this.$http.get(config.menu.host + '/package/contents').then(resp => {
            response.body.package.map(function (ele) {
              resp.body.map(function (arg) {
                if (ele.provider_id === arg.provider_id) {
                  ele.provider_category.map(function (data) {
                    if (data.id === arg.sc_type) {
                      data.count = arg.count
                    }
                  })
                }
              })
            })
            this.items = (response.body.package.length > 0) ? response.body.package : []
          })
        }
      })
    },
    createConfig () {
      this.reset()
      this.error_msg.packageNameErrors = this.form_data.package_name ? [] : ['Package Name is required']
      this.error_msg.packageAmountErrors = this.form_data.amount ? [] : ['Package Amount is required']
      this.error_msg.providerNameErrors = this.form_data.provider_name ? [] : ['Provider Name is required']
      this.error_msg.providerCategoryErrors = this.form_data.provider_category.length ? [] : ['Provider Category is required']
      this.error_msg.providerTypeErrors = this.form_data.provider_type.length ? [] : ['Provider Type is required']

      if (!this.formReady) {
        return
      }
      var vm = this
      this.provider_info.map(function (ids) {
        if (ids.brand === vm.form_data.provider_name) {
          vm.form_data.provider_id = ids.provider_id
        }
      })
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/package', this.form_data).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateConfig () {
      this.reset()
      this.error_msg.packageNameErrors = this.form_data.package_name ? [] : ['Package Name is required']
      this.error_msg.packageAmountErrors = this.form_data.amount ? [] : ['Package Amount is required']
      this.error_msg.providerNameErrors = this.form_data.provider_name ? [] : ['Provider Name is required']
      this.error_msg.providerCategoryErrors = this.form_data.provider_category.length ? [] : ['Provider Category is required']
      this.error_msg.providerTypeErrors = this.form_data.provider_type.length ? [] : ['Provider Type is required']

      if (!this.formReady) {
        return
      }
      var vm = this
      this.provider_info.map(function (ids) {
        if (ids.brand === vm.form_data.provider_name) {
          vm.form_data.provider_id = ids.provider_id
        }
      })
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/package/' + this.package_id, this.form_data).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    getProvider () {
      this.$http.get(config.menu.host + '/provider').then(response => {
        var nameArray = []
        if (response.body.length > 0) {
          this.provider_info = (response.body.length > 0) ? response.body : []
          response.body.map(function (item) {
            nameArray.push(item.brand)
          })
        } else {
          nameArray = []
        }
        this.name_data = nameArray
      })
    },
    dataUpdate (data) {
      this.$http.get(config.menu.host + '/provider').then(response => {
        this.provider_info = (response.body.length > 0) ? response.body : []
        // this.form_data.provider_category = []
        // this.form_data.provider_type = ''
        var vm = this
        this.provider_data = []
        this.type_data = []
        this.provider_info.map(function (item) {
          if (item.brand === data) {
            var category, type
            category = JSON.parse(item.service_category)
            type = JSON.parse(item.service_type)
            category.map(function (categoryData) {
              vm.provider_data.push({ id: categoryData.id })
            })
            // vm.provider_data.unshift({id:'All'})
            type.map(function (typeData) {
              vm.type_data.push(typeData.id)
            })
          }
        })
      })
    },
    getRole () {
      return Vue.$cookies.get('userRole')
    },
    add () {
      this.getProvider()
      this.title = 'Create Package'
      this.form_data.package_name = ''
      this.form_data.provider_name = ''
      this.form_data.amount = 0
      this.form_data.provider_category = []
      this.form_data.provider_type = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    edit (row) {
      this.dataUpdate(row.provider_name)
      this.title = 'Update Package'
      this.package_id = row.package_id
      this.form_data.package_name = row.package_name
      this.form_data.provider_name = row.provider_name
      this.form_data.provider_type = row.provider_type
      this.form_data.amount = row.amount

      row.provider_category.map(function (prop) {
        if (prop.count) {
          delete prop.count
        }
      })
      this.form_data.provider_category = row.provider_category
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    showchannellist (row) {
      function replaceCommaLine(data) {
        let dataToArray = data.split(',')
        dataToArray = dataToArray.map(function (item, i) {
          var datas = (i+1)+'.  '+item.trim()
          return datas
        })
        return dataToArray.join("\n");
      }
      this.isshowForm = false
      this.isshowgrid = true
      this.showRemoveModal = true
      let availableData = row.packchannel.toString()
      this.message = replaceCommaLine(availableData);
      this.package_id = row.package_id
    },
    reset () {
      this.error_msg.typeErrors = []
      this.error_msg.packageNameErrors = []
      this.error_msg.providerNameErrors = []
      this.error_msg.providerCategoryErrors = []
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.showRemoveModal = false
      this.readItems()
      this.error_msg.packageNameErrors = []
      this.error_msg.packageAmountErrors = []
      this.error_msg.providerNameErrors = []
      this.error_msg.providerCategoryErrors = []
      this.error_msg.providerTypeErrors = []
    },
    search: debounce(function (term) {
      this.term = term
      var getData = search(this.term, this.packageList)
      this.items = getData
    }, 400),
  },
}
</script>
<style type="text/css">
.radio_options {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

h4 {
  line-height: 0.2;
}

.packcard {
  height: 170px;
  padding: 0.1px;
}

.separator {
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #ddbf1a;
}

.cursor {
  cursor: pointer;
  padding-left: 140px;
  position: absolute;
  right: 2px;
  top: 5;
}

.cursor_tv {
  cursor: pointer;
  padding-left: 140px;
  position: absolute;
  right: 27px;
  top: 5;
}
</style>
